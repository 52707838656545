<template>
  <b-card title="Client">
    <b-row>
      <b-col cols="12" md="6" lg="2" class="mb-1">
        <b-form-group label="Nom" label-for="firstName">
          <b-form-input id="firstName" v-model="client.firstName" trim />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="2" class="mb-1">
        <b-form-group label="Llinatges" label-for="lastName">
          <b-form-input id="lastName" v-model="client.lastName" trim />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="2" class="mb-1">
        <b-form-group label="Email" label-for="email">
          <b-form-input id="email" v-model="client.email" type="email" trim />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="2" class="mb-1 mb-md-0">
        <b-form-group label="Telèfon" label-for="phone">
          <b-form-input id="phone" v-model="client.phone" trim />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="2" class="mb-1 mb-md-0">
        <b-form-group label="Tipus document" label-for="idType">
          <v-select
            id="idType"
            v-model="client.idType"
            :options="idTypeOptions"
            :reduce="(option) => option.value"
            class="w-full"
            :clearable="false"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="2">
        <b-form-group label="Nº document" label-for="idNumber">
          <b-form-input id="idNumber" v-model="client.idNumber" trim />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import { idTypeOptions } from "@/utils/select-options";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      client: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        idType: null,
        idNumber: null,
      },
      idTypeOptions,
    };
  },
  watch: {
    client: {
      deep: true,
      handler() {
        this.$emit("change", this.client);
      },
    },
  },
};
</script>
