<template>
  <b-card title="Extres">
    <b-row>
      <b-col cols="12" md="6" lg="2" class="mb-1">
        <b-form-group label="Maxi Cosi (gratuït)" label-for="maxi-cosis">
          <b-form-spinbutton
            id="maxi-cosis"
            v-model="extras.maxiCosis"
            min="0"
            max="10"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="2" class="mb-1">
        <b-form-group
          label="Seient per a nadons (gratuït)"
          label-for="baby-seats"
        >
          <b-form-spinbutton
            id="baby-seats"
            v-model="extras.babySeats"
            min="0"
            max="10"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="2" class="mb-1">
        <b-form-group
          label="Seient elevador G3 (gratuït)"
          label-for="booster-seats"
        >
          <b-form-spinbutton
            id="booster-seats"
            v-model="extras.boosterSeats"
            min="0"
            max="10"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="2" class="mb-1 mb-md-0">
        <b-form-group
          label="Carret per a nadons (gratuït)"
          label-for="baby-trolleys"
        >
          <b-form-spinbutton
            id="baby-trolleys"
            v-model="extras.babyTrolleys"
            min="0"
            max="10"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="2" class="mb-1 mb-md-0">
        <b-form-group label="Bicicleta (4,00 €/unitat)" label-for="bicycles">
          <b-form-spinbutton
            id="bicycles"
            v-model="extras.bicycles"
            min="0"
            max="10"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="2">
        <b-form-group
          label="Set de pals de golf (4,00 €/set)"
          label-for="golfSet"
        >
          <b-form-spinbutton
            id="golfSet"
            v-model="extras.golfSets"
            min="0"
            max="10"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormGroup, BFormSpinbutton } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSpinbutton,
  },
  data() {
    return {
      extras: {
        maxiCosis: 0,
        babySeats: 0,
        boosterSeats: 0,
        babyTrolleys: 0,
        bicycles: 0,
        golfSets: 0,
      },
    };
  },
  watch: {
    extras: {
      deep: true,
      handler() {
        this.$emit("change", this.extras);
      },
    },
  },
  created() {
    this.$emit("change", this.extras);
  },
};
</script>
