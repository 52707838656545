<template>
  <div>
    <b-row>
      <b-col cols="12">
        <details-card @change="onDetailsUpdated" />
      </b-col>
      <b-col cols="12">
        <extras-card @change="onExtrasUpdated" />
      </b-col>
      <b-col cols="12">
        <client-card @change="onClientUpdated" />
      </b-col>
      <b-col cols="12">
        <b-card title="Preu">
          <b-row>
            <b-col cols="12" md="6" lg="3">
              <h6>Transfer</h6>
              <b-input-group append="€">
                <b-form-input
                  v-model="basePrice"
                  type="number"
                  class="text-right"
                  @input="calculateTotalAndVat"
                />
              </b-input-group>
            </b-col>
            <b-col cols="12" md="6" lg="3">
              <h6>Extres</h6>
              <b-input-group append="€">
                <b-form-input
                  v-model="extrasPrice"
                  type="number"
                  class="text-right"
                  @input="calculateTotalAndVat"
                />
              </b-input-group>
            </b-col>
            <b-col cols="12" md="6" lg="3">
              <h6>PVP</h6>
              <b-input-group append="€">
                <b-form-input v-model="pvpPrice" class="text-right" readonly />
              </b-input-group>
            </b-col>
            <b-col cols="12" md="6" lg="3">
              <h6>I.V.A.</h6>
              <b-input-group append="€">
                <b-input-group-prepend>
                  <b-form-select
                    v-model="vatTypeInput"
                    :options="vatTypeOptions"
                  />
                </b-input-group-prepend>
                <b-form-input v-model="vatPrice" class="text-right" readonly />
              </b-input-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-2 d-flex justify-content-center justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="secondary" @click="cancel">
          Cancel·la
        </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
} from "bootstrap-vue";
import DetailsCard from "@/views/transfers/new-transfer/components/DetailsCard.vue";
import ExtrasCard from "@/views/transfers/new-transfer/components/ExtrasCard.vue";
import ClientCard from "@/views/transfers/new-transfer/components/ClientCard.vue";
import { notifyError } from "@/utils/methods";
import transferRates from "@transfer-rates";
import { formatCurrency } from "@/utils/formatters";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    DetailsCard,
    ExtrasCard,
    ClientCard,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormSelect,
  },
  data() {
    return {
      details: null,
      extras: null,
      client: null,
      basePrice: null,
      extrasPrice: null,
      pvpPrice: null,
      vatPrice: null,
      vatTypeInput: 21,
      vatTypeOptions: [
        { value: 0, text: "0 %" },
        { value: 10, text: "10 %" },
        { value: 21, text: "21 %" },
      ],
      formatCurrency,
    };
  },
  computed: {
    transferExtrasRates() {
      return transferRates.extras;
    },
  },
  watch: {
    vatTypeInput() {
      this.calculatePrice();
    },
  },
  methods: {
    onDetailsUpdated(details) {
      this.details = details;
      this.calculatePrice();
    },
    onExtrasUpdated(extras) {
      this.extras = extras;
      this.calculatePrice();
    },
    onClientUpdated(client) {
      this.client = client;
    },
    calculatePrice() {
      if (!this.details?.passengers) return;

      // Get the city rates or the default rate if city was not found
      const cityRates =
        transferRates.cities.find((city) => {
          return city.slug === this.details?.accommodationCity;
        }, this) || transferRates.base;

      // Calculate the base price
      this.basePrice =
        this.calculateBasePrice(cityRates, this.details.passengers) / 100;

      // Calculate the price of the extras
      this.extrasPrice =
        this.calculateExtrasPrice(transferRates.extras, this.extras) / 100;

      // Calculate the total price and the VAT price
      this.calculateTotalAndVat();
    },
    calculateBasePrice(cityRates, passengers) {
      if (!cityRates || !passengers) return null;
      if (passengers > 0 && passengers <= 4) return cityRates.oneToFour;
      if (passengers >= 5 && passengers < 9) return cityRates.fiveToEight;
      return cityRates.nineToTwelve;
    },
    calculateExtrasPrice(extrasRates, extras) {
      if (!extrasRates) return null;
      if (!extras) return 0;

      let price = 0;

      if (extras.boosterSeats > 0) {
        const extra = extrasRates.find(
          (extraItem) => extraItem.code === "BOOSTER_SEAT"
        );
        price += extra ? extra.price * extras.boosterSeats : 0;
      }
      if (extras.babyTrolleys > 0) {
        const extra = extrasRates.find(
          (extraItem) => extraItem.code === "BABY_TROLLEY"
        );
        price += extra ? extra.price * extras.babyTrolleys : 0;
      }
      if (extras.maxiCosis > 0) {
        const extra = extrasRates.find(
          (extraItem) => extraItem.code === "MAXI_COSI"
        );
        price += extra ? extra.price * extras.maxiCosis : 0;
      }
      if (extras.babySeats > 0) {
        const extra = extrasRates.find(
          (extraItem) => extraItem.code === "BABY_SEAT"
        );
        price += extra ? extra.price * extras.babySeats : 0;
      }
      if (extras.bicycles > 0) {
        const extra = extrasRates.find(
          (extraItem) => extraItem.code === "BICYCLE"
        );
        price += extra ? extra.price * extras.bicycles : 0;
      }
      if (extras.golfSets > 0) {
        const extra = extrasRates.find(
          (extraItem) => extraItem.code === "GOLF_SET"
        );
        price += extra ? extra.price * extras.golfSets : 0;
      }

      return price;
    },
    calculateTotalAndVat() {
      if (!this.basePrice) this.basePrice = 0;
      if (!this.extrasPrice) this.extrasPrice = 0;

      this.pvpPrice = parseFloat(this.basePrice) + parseFloat(this.extrasPrice);

      const calculatedVatPrice =
        this.pvpPrice - this.pvpPrice / (1 + this.vatTypeInput / 100);
      this.vatPrice = Math.round(calculatedVatPrice * 100) / 100;
    },
    cancel() {
      this.$router.push({ name: "foravila-transfers-list" });
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("transfers/createTransfer", {
          booking: this.details.booking,
          client: this.client,
          transfer: {
            ...this.details,
            extras: this.extras,
            basePrice: this.basePrice * 100,
            extrasPrice: this.extrasPrice * 100,
            pvpPrice: this.pvpPrice * 100,
            vatPrice: this.vatPrice * 100,
          },
        })
        .then((response) => {
          this.$router.push({
            name: "foravila-transfer-view",
            params: { transferUuid: response.data.uuid },
          });
        })
        .catch(() => {
          notifyError(
            "Transfer no creat",
            "El transfer no s'ha pogut crear correctament, si us plau, revisa les dades introduïdes"
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
