<template>
  <b-card title="Detalls">
    <b-row>
      <b-col cols="12" md="6" lg="4" class="mb-1">
        <b-form-group label="Reserva" label-for="booking">
          <v-select
            id="booking"
            v-model="details.booking"
            :options="bookingOptions"
            :reduce="(option) => option.value"
            :clearable="false"
            @search="fetchOptions"
            @option:selecting="onBookingSelected"
          >
            <template slot="no-options">
              Escriu el localitzador de la reserva...
            </template>
            <template #option="option">
              <div>{{ option.localizator }} a {{ option.accommodation }}</div>
              <div>
                <small>
                  {{ option.client }}
                </small>
              </div>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="4" class="mb-1">
        <b-form-group label="Orígen" label-for="pickupLocation">
          <v-select
            id="pickupLocation"
            v-model="details.pickupLocation"
            :options="locationOptions"
            :reduce="(option) => option.value"
            :clearable="false"
            :disabled="!selectedBookingOption"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="4" class="mb-1">
        <b-form-group label="Destí" label-for="dropoffLocation">
          <v-select
            id="dropoffLocation"
            v-model="details.dropoffLocation"
            :options="locationOptions"
            :reduce="(option) => option.value"
            :clearable="false"
            :disabled="!selectedBookingOption"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6" lg="2" class="mb-1">
        <b-form-group label="Data" label-for="pickup-date">
          <b-form-datepicker
            id="pickup-date"
            v-model="details.pickupDate"
            :locale="$i18n.locale"
            :start-weekday="1"
            :show-decade-nav="true"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
            hide-header
            class="w-full"
            label-no-date-selected=""
            tabindex="1"
            :disabled="!selectedBookingOption"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2" class="mb-1">
        <b-form-group label="Hora" label-for="pickup-time">
          <b-form-timepicker
            id="pickup-time"
            v-model="details.pickupTime"
            label-no-time-selected=""
            locale="es"
            minutes-step="10"
            hide-header
            no-close-button
            tabindex="2"
            :disabled="!selectedBookingOption"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2" class="mb-1">
        <b-form-group label="Passatgers" label-for="passengers">
          <b-form-spinbutton
            id="passengers"
            v-model="details.passengers"
            min="1"
            max="12"
            step="1"
            :disabled="!selectedBookingOption"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2">
        <b-form-group label="Nº vol" label-for="flight-number">
          <b-form-input
            id="flight-number"
            v-model="details.flightNumber"
            maxlength="10"
            trim
            :disabled="!selectedBookingOption"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSpinbutton,
  BFormDatepicker,
  BFormTimepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { notifyError, getPlaceTypeName } from "@/utils/methods";
import _debounce from "lodash/debounce";
import axios from "@/axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSpinbutton,
    BFormDatepicker,
    BFormTimepicker,
    vSelect,
  },
  data() {
    return {
      details: {},
      bookingOptions: [],
      selectedBookingOption: null,
      airportLocation: null,
    };
  },
  computed: {
    locationOptions() {
      const options = [];
      if (this.selectedBookingOption) {
        options.push({
          value: this.selectedBookingOption.accommodationLocation,
          label: `${this.$t("Allotjament")} - ${
            this.selectedBookingOption.accommodation
          }`,
        });
      }
      this.places.forEach((place) => {
        if (!place.location) return;
        options.push({
          value: place.location["@id"],
          label: `${getPlaceTypeName(place.type)} - ${place.name}`,
        });
      });
      return options;
    },
    loadingPlaces() {
      return this.$store.getters["places/loading"];
    },
    places() {
      return this.$store.getters["places/places"];
    },
  },
  watch: {
    details: {
      deep: true,
      handler() {
        this.$emit("change", this.details);
      },
    },
  },
  created() {
    if (this.places.length === 0 && !this.loadingPlaces) this.fetchPlaces();
    this.fetchAirportLocation();
  },
  methods: {
    fetchOptions(search, loading) {
      if (search.length) {
        loading(true);
        this.fetchBookings(loading, search, this);
      }
    },
    fetchBookings: _debounce((loading, search, innerThis) => {
      innerThis.$store
        .dispatch("bookings/fetchFilteredBookings", {
          pagination: true,
          itemsPerPage: 10,
          page: 1,
          searchText: search || "",
        })
        .then((response) => {
          innerThis.bookingOptions = innerThis.mapTableBookingsArray(response);
        })
        .catch(() =>
          notifyError(
            innerThis.$t("errors.fetchBookings.title"),
            innerThis.$t("errors.fetchBookings.description")
          )
        )
        .finally(() => loading(false));
    }, 500),
    mapTableBookingsArray(bookings) {
      if (!bookings.length) return [];
      return bookings.map((booking) => ({
        value: booking["@id"] || null,
        label: `${booking.localizator} a ${booking.accommodation?.name}`,
        accommodation: booking.accommodation?.name || null,
        accommodationLocation: booking.accommodation?.location
          ? booking.accommodation.location["@id"]
          : null,
        accommodationCity: booking.accommodation?.location
          ? booking.accommodation.location.citySlug
          : null,
        localizator: booking.localizator || null,
        client: booking.client?.fullName || null,
      }));
    },
    fetchPlaces() {
      this.$store
        .dispatch("places/fetchPlaces", { pagination: false, sort: "asc" })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchPlaces.title"),
            this.$t("errors.fetchPlaces.description")
          )
        );
    },
    fetchAirportLocation() {
      axios
        .get("/places?type=AIRPORT&location.regionSlug=mallorca")
        .then((response) => {
          if (response.data["hydra:totalItems"] > 0) {
            this.airportLocation = response.data["hydra:member"][0].location;
          }
        });
    },
    onBookingSelected(selectedOption) {
      this.selectedBookingOption = selectedOption;
      this.details.accommodationCity = selectedOption.accommodationCity;
    },
  },
};
</script>
